import format from "comma-number";
import { useRef, useState } from "react";
import useSWR from "swr";
import fetcher from "~/lib/fetcher";

function ErrorMessage({ children }) {
  return (
    <p className="flex items-center text-sm font-bold text-red-800 dark:text-red-400">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="mr-2 h-4 w-4">
        <path
          fillRule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
          clipRule="evenodd"
        />
      </svg>
      {children}
    </p>
  );
}

function SuccessMessage({ children }) {
  return (
    <p className="flex items-center text-sm font-bold text-green-800 dark:text-green-400">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="mr-2 h-4 w-4">
        <path
          fillRule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
          clipRule="evenodd"
        />
      </svg>
      {children}
    </p>
  );
}

export default function Subscribe() {
  const [form, setForm] = useState({
    state: "initial",
    message: ""
  });
  const inputEl = useRef(null);
  const { data } = useSWR("/api/subscribers", fetcher);
  const subscriberCount = format(data?.count);

  const subscribe = async (e) => {
    e.preventDefault();
    setForm({ ...form, state: "loading" });

    const res = await fetch("/api/subscribe", {
      body: JSON.stringify({
        email: inputEl.current.value
      }),
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST"
    });

    const { error } = await res.json();
    if (error) {
      setForm({
        state: "error",
        message: error
      });
      return;
    }

    inputEl.current.value = "";
    setForm({
      state: "success",
      message: `Hooray! You're now on the list.`
    });
  };

  return (
    <div className="border border-blue-200 rounded p-6 my-4 w-full dark:border-blue-800 bg-blue-50 dark:bg-blue-900">
      <h5 className="text-lg md:text-xl font-bold text-gray-900 dark:text-gray-100">Subscribe to Blog</h5>
      <p className="my-1 text-gray-800 dark:text-gray-200">
        I almost never post in it, which should make it worth your time.
        <br />
        {/* <a href="https://buttondown.email/sambatista/archive" target="__blank">
          Click here to view my email archive.
        </a> */}
      </p>
      <div className="relative my-4">
        <input
          ref={inputEl}
          aria-label="Email for newsletter"
          placeholder="email@example.com"
          type="email"
          autoComplete="email"
          required
          className="px-4 py-2 mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full border-gray-300 rounded-md bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
        />
        <button
          className="flex items-center justify-center absolute right-1 top-1 px-4 font-bold h-8 bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-gray-100 rounded w-28"
          onClick={subscribe}
        >
          {form.state === "loading" ? (
            <svg
              className="animate-spin h-5 w-5 text-gray-900 dark:text-gray-100"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          ) : (
            "Subscribe"
          )}
        </button>
      </div>
      {form.state === "error" ? (
        <ErrorMessage>{form.message}</ErrorMessage>
      ) : form.state === "success" ? (
        <SuccessMessage>{form.message}</SuccessMessage>
      ) : (
        <p className="text-sm text-gray-800 dark:text-gray-200">
          {`${subscriberCount ? `${subscriberCount}-subscribers` : ""}`}
        </p>
      )}
    </div>
  );
}
