export default function ProjectCard({
  title = "",
  description = "",
  href = "",
  icon = "",
  target = "",
  svgIcon = null,
  svgPath = null
}) {
  return (
    <a
      className="no-underline mb-4 hover:shadow hover:text-gray-900 hover:dark:text-gray-100"
      href={href}
      // onClick={() => trackGoal(title)}
      aria-label={title}
      target={target}
      rel="noopener noreferrer"
    >
      <div className="flex items-center border border-gray-200 dark:border-gray-800 rounded p-4">
        <div className={svgIcon || svgPath ? "h-8 w-8 ml-2 mr-4" : ""}>
          {icon && <span className="sr-only">{icon}</span>}
          {svgIcon && svgIcon}
          {svgPath && (
            <svg
              className="stroke-current h-8 w-8 min-w-sm text-gray-900 dark:text-gray-100"
              viewBox="0 0 24 24"
              stroke="currentColor"
              fill="none"
            >
              {svgPath}
            </svg>
          )}
        </div>
        <div>
          <h4 className="underline text-lg font-bold tracking-tight text-gray-900 dark:text-gray-100">{title}</h4>
          <p className="leading-5 text-gray-700 dark:text-gray-300">{description}</p>
        </div>
      </div>
    </a>
  );
}
