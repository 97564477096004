import Link from "next/link";
import { useState } from "react";
import { title, url } from "~/pages/work/open-source/portable-secret";

export const Divider = () => {
  return <div className="border border-gray-200 dark:border-gray-600 w-full my-8" />;
};

const Year = ({ children }) => {
  return (
    <h3 className="text-lg md:text-xl font-bold mb-4 tracking-tight text-gray-900 dark:text-gray-100">{children}</h3>
  );
};

const Step = ({ title, children }) => {
  return (
    <li className="mb-4 ml-2">
      <div className="flex items-center mb-2 text-green-700 dark:text-green-300">
        <span className="sr-only">Check</span>
        <svg className="h-4 w-4 mr-2" viewBox="0 0 24 24">
          <g fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
            <path d="M22 4L12 14.01l-3-3" />
          </g>
        </svg>
        <p className="font-medium text-gray-900 dark:text-gray-100">{title}</p>
      </div>
      <p className="text-gray-700 dark:text-gray-400 ml-6">{children}</p>
    </li>
  );
};

const FullHistory = () => (
  <>
    <Divider />
    <Year>09/2017 to 02/2019</Year>
    <ul>
      <Step title="Senior UI Programmer at Firaxis Games">
        Developed user interfaces for big budget video games. Games I worked on as a UI Programmer: XCOM: Enemy Unknown,
        XCOM: Enemy Within, XCOM 2 and Sid Meier&apos;s Civilization VI.
      </Step>
    </ul>
    <Year>04/2018 to 02/2019</Year>
    <ul>
      <Step title="Front End Supervisor at Lykke">
        Supervised a team of engineers tasked with building a complex web application with React & TypeScript.
      </Step>
    </ul>
    <Year>08-2017 to 04/2018</Year>
    <ul>
      <Step title="Front End Programmer at Lykke">
        Worked on a complex web application with React & TypeScript for a Swiss FinTech company building a global
        marketplace on the blockchain. This opportunity allowed me to learn new technologies and skills in a competitive
        startup environment.
      </Step>
    </ul>
    <Divider />
    <Year>09/2015 to 12/2015</Year>
    <ul>
      <Step title="Front End Programmer at Storj">
        Helped develop the user interface for{" "}
        <a href="https://github.com/storj/storjshare-gui" target="__blank">
          Storj Share
        </a>
        , an Electron based GUI for Storj, an open source and decentralized data storage application.
      </Step>
    </ul>
    <Divider />
    <Year>05/2011 to 09/2017</Year>
    <ul>
      <Step title="UI Programmer at Firaxis Games">
        Developed user interfaces for big budget video games. Games I worked on as a UI Programmer: XCOM: Enemy Unknown,
        XCOM: Enemy Within, XCOM 2 and Sid Meier&apos;s Civilization VI.
      </Step>
    </ul>
    <Divider />
    <Year>05/2010 to 05/2011</Year>
    <ul>
      <Step title="UI Programmer at TimeGate Studios">
        Programmed several screens for Section 8: Prejudice using Flash and Actionscript. Extended and repaired the
        functionality of Unreal Engine&apos;s Online Subsystems (hosting, searching, inviting and joining games) for
        PS3, Xbox 360 and PC.
      </Step>
    </ul>
    <Divider />
    <Year>03/2010 to 05/2010</Year>
    <ul>
      <Step title="Web Tools Programmer at Sim Ops Studios">
        Wrote Collada importer for WildPockets game engine. Extended functionality of the web based game engine&apos;s
        tools using Lua.
      </Step>
    </ul>
    <Divider />
    <Year>12/2007 to 11/2009</Year>
    <ul>
      <Step title="Graduated from College">
        Obtained my BS Game Development from Full Sail University, it was challenging and gave me a solid foundation of
        Computer Science.
      </Step>
    </ul>
    <Divider />
    <Year>2007</Year>
    <ul>
      <Step title="Graduated High School">Not much to say about this, it was easier I than expected...</Step>
    </ul>
    <Divider />
    <Year>2004</Year>
    <ul>
      <Step title="Moved to the USA">Came to the land of opportunity to pursue my dreams.</Step>
    </ul>
  </>
);

export function History() {
  const [isShowingFullHistory, showFullHistory] = useState(false);

  return (
    <>
      <h3 className="font-bold text-2xl md:text-4xl tracking-tight mb-4 mt-8 text-black dark:text-white">History</h3>
      <Year>01/2021 - Present</Year>
      <ul>
        <Step title="Senior Software Engineer at Salesforce">
          Joined{" "}
          <a href="https://www.salesforce.com" target="__blank">
            Salesforce
          </a>{" "}
          to help build a modern publishing experience for AppExchange partners. My team is using Web Components,
          JavaScript, HTML & CSS on the frontend and NodeJS, NestJS & Postgres on the backend. I create high quality,
          high performance UI components and use them to build complex, high performance, and easy to use web
          applications.
        </Step>
      </ul>
      <Divider />
      <Year>01/01/2023</Year>
      <ul>
        <Step title="Open Source Application">
          Created a free and open source program to create encrypted files using web browsers:{" "}
          <Link href={url}>{title}</Link>.
        </Step>
      </ul>
      <Divider />
      <Year>01/2021 - 04-2021</Year>
      <ul>
        <Step title="Website Development">
          Built a website for{" "}
          <a href="https://indiemakersyndicate.com/pr" target="__blank">
            Indie Maker Syndicate
          </a>
          , an experienced group of creators with backgrounds in promoting independent games and maker-based projects.
        </Step>
      </ul>
      <Divider />
      <Year>02-2019 - 01/2021</Year>
      <ul>
        <Step title="Web Tools Engineer at Big Huge Games">
          Built web tools to enable my coworkers to achieve more with less time & effort. Our stack consisted of React +
          TypeScript on the frontend, and C#, Java + Spring Framework, MySQL and AWS services on the backend.
        </Step>
        <Step title="WordPress Contracting">Built a website for a Cannabis Investment Conference.</Step>
      </ul>
      {isShowingFullHistory ? (
        <FullHistory />
      ) : (
        <button
          type="button"
          className="underline flex items-center text-sm my-4 mx-auto px-4 py-2 rounded-md font-medium text-gray-900 dark:text-gray-100"
          onClick={() => showFullHistory(true)}
        >
          See More
          <svg
            className="h-4 w-4 ml-1"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </svg>
        </button>
      )}
    </>
  );
}
