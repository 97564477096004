import { BASE_URL } from "#next-seo.config";
import { NextSeo } from "next-seo";
import Image from "next/image";
import Link from "next/link";
import { useEffect } from "react";
import useSWR from "swr";
import { WorkList } from "~/components/WorkList";
import fetcher from "~/lib/fetcher";
import { getAllFilesFrontMatter } from "~/lib/mdx";
import BlogPost from "../components/BlogPost";
import Container from "../components/Container";
import { Divider, History } from "../components/History";
import Subscribe from "../components/Subscribe";

const url = BASE_URL;
const title = "About Samuel Batista";
const description = "Learn about me and my work.";

export async function getStaticProps() {
  const [portableSecret] = await Promise.all([import("./work/open-source/portable-secret")]);

  const posts = await getAllFilesFrontMatter("blog");
  let latestPost = undefined;
  for (const post of posts) {
    if (!latestPost || post.publishedAt >= latestPost.publishedAt) {
      latestPost = post;
    }
  }

  return {
    props: {
      posts,
      latestPost,
      portableSecret: {
        title: portableSecret.title,
        url: portableSecret.url,
        description: portableSecret.description
      }
    }
  };
}

export default function Home({ latestPost, portableSecret }) {
  const { data, error } = useSWR(`/api/blog/most_viewed`, fetcher);

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  return (
    <Container>
      <NextSeo
        title={title}
        description={description}
        canonical={url}
        openGraph={{
          url,
          title,
          description
        }}
      />
      <div className="flex flex-col justify-center items-start max-w-2xl mx-auto mb-4">
        <div
          className="flex justify-left"
          style={{
            overflow: "visible",
            paddingBottom: "2rem",
            paddingTop: "0.5rem"
          }}
        >
          <Image
            className="rounded-full ring-4"
            src="/images/avatar.webp"
            alt="Picture of Samuel Batista"
            width={138}
            height={138}
          />
        </div>
        <h1 className="font-bold text-3xl md:text-5xl tracking-tight mb-4 text-black dark:text-white">
          Samuel Batista
        </h1>
        <br />
        <h2 className="text-gray-600 dark:text-gray-400 mb-4">
          Born and raised in Portugal, I moved to the USA to pursue my dream of becoming a video game developer.
          <br />
          <br />
          After graduating from Full Sail University with a degree in Game Development, I spent nearly a decade
          developing games at{" "}
          <a href="https://firaxis.com/" target="__blank">
            Firaxis
          </a>{" "}
          and{" "}
          <a href="https://bighugegames.com/" target="__blank">
            Big Huge Games
          </a>
          .
          <br />
          <br />
          Never content with the status quo, I learned how to build websites and complex web applications using React,
          Node and other modern web technologies.
          <br />
          <br />
          In my free time I play video games, contribute to open source software, participate in hackathons and tackle
          new projects to keep growing as a developer.
          <br />
          <br />I love programming, open source software, video games, economics, teaching & learning.
        </h2>
        <Divider />
        <h3 className="font-bold text-2xl md:text-4xl tracking-tight mb-4 mt-8 text-black dark:text-white">
          Latest Post
        </h3>
        <BlogPost title={latestPost.title} summary={latestPost.summary} slug={latestPost.slug} />
        <h3 className="font-bold text-2xl md:text-4xl tracking-tight mb-4 mt-8 text-black dark:text-white">
          Most Viewed Post
        </h3>
        <BlogPost
          title={data?.post?.title || "Loading..."}
          summary={data?.post?.summary || "Loading..."}
          slug={data?.post?.slug || ""}
          views={data?.views}
        />
        <Link
          href="/blog"
          className="underline flex items-center text-sm my-4 mx-auto px-4 py-2 rounded-md font-medium text-gray-900 dark:text-gray-100"
        >
          All Posts
        </Link>
        <Divider />
        <h3 className="font-bold text-2xl md:text-4xl tracking-tight mb-4 mt-8 text-black dark:text-white">Work</h3>
        <WorkList portableSecret={portableSecret} />
        <Divider />
        <History />
        <Divider />
        <Subscribe />
      </div>
    </Container>
  );
}
